import { v4 as uuid } from "uuid";
import { Storage } from "./Storage.js";
export var FileType;
(function (FileType) {
    FileType["BINARY"] = "binary";
    FileType["JSON"] = "json";
})(FileType || (FileType = {}));
export class File {
    id;
    fileType;
    written = false;
    downloadURL;
    constructor({ id, fileType } = {}) {
        this.id = id ?? uuid();
        this.fileType = fileType;
    }
    async read() {
        if (this.fileType === FileType.BINARY) {
            return this.readBinary();
        }
        else if (this.fileType === FileType.JSON) {
            return this.readJSON();
        }
        else {
            throw new Error(`Reading file of unknown filetype : ${this.id}`);
        }
    }
    async readBinary() {
        return Storage.INSTANCE.readBinary(this.id);
    }
    async readJSON() {
        return Storage.INSTANCE.readJSON(this.id);
    }
    async write(options) {
        if (this.fileType === FileType.BINARY) {
            return this.writeBinary(options);
        }
        else if (this.fileType === FileType.JSON) {
            return this.writeJSON(options);
        }
        else {
            throw new Error(`Writing file of unknown filetype : ${this.id}`);
        }
    }
    async writeBinary(options) {
        this.fileType = FileType.BINARY;
        const ret = await Storage.INSTANCE.writeBinary({
            ...options,
            id: this.id,
        });
        this.id = ret.id;
        this.downloadURL = ret.downloadURL;
        this.written = true;
        return ret;
    }
    async writeJSON(options) {
        const ret = await Storage.INSTANCE.writeJSON({
            ...options,
            id: this.id,
        });
        this.fileType = FileType.JSON;
        this.id = ret.id;
        this.downloadURL = ret.downloadURL;
        this.written = true;
        return ret;
    }
    toJSON() {
        return {
            id: this.id,
            fileType: this.fileType,
            downloadURL: this.downloadURL,
        };
    }
}
