export const BASE_URI = "https://api.multitool.xyz/v1";
const USER_ID_HEADER = "user";
const ENV_HEADER = "env";
export async function fetchAPI(path, { user, env }, init) {
    try {
        return await fetch(`${BASE_URI}${path}`, {
            ...init,
            headers: {
                [USER_ID_HEADER]: user,
                ...(env && { [ENV_HEADER]: env }),
                ...init?.headers,
            },
        });
    }
    catch (error) {
        console.error("Error in api request:", error);
        throw error;
    }
}
