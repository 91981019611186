import styles from "./AppLayout.module.css";

import { ReactNode, StrictMode } from "react";
import AppHeader from "@/components/AppHeader";
import ThemeProvider from "@/providers/ThemeProvider.tsx";

import "@/reset.css";
import "@/index.css";

import { GoogleTagManagerProvider } from "@tracktor/react-google-tag-manager";
import { MultitoolBrowser } from "multitool-sdk-browser";
import { Config } from "vike-react/Config";
import { Head } from "vike-react/Head";
import { env, userId } from "@/api/config";

const MULTITOOL_CLIENT_GOOGLE_TAG_MANAGER_ID = import.meta.env
  .MULTITOOL_CLIENT_GOOGLE_TAG_MANAGER_ID;

MultitoolBrowser.init({
  env: env,
  user: userId,
});

export default function App({ children }: { children: ReactNode }) {
  return (
    <StrictMode>
      <GoogleTagManagerProvider id={MULTITOOL_CLIENT_GOOGLE_TAG_MANAGER_ID}>
        <ThemeProvider>
          <div className={styles.app}>
            <Config title="Multitool.xyz" />
            <Head>
              <meta
                name="title"
                content="Multitool.xyz - Free Utility Tools & APIs for File Conversion, Calculators, and More"
              />
              <meta
                name="description"
                content="Multitool.xyz offers a collection of free online utilities including file conversions, calculators, and various APIs. Explore our simple and efficient tools for your everyday needs."
              />
              <meta
                name="keywords"
                content="Multitool, file conversion, online calculator, free utilities, APIs, file tools, unit converters, free online tools"
              />
              <meta name="robots" content="index, follow" />
              <meta name="author" content="Multitool.xyz" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Head>

            <AppHeader />

            <main className={styles.main}>{children}</main>
          </div>
        </ThemeProvider>
      </GoogleTagManagerProvider>
    </StrictMode>
  );
}
