import { Storage } from "./Storage.js";
export class Multitool {
    static INSTANCE;
    user;
    env;
    storage;
    constructor({ env, user, storageAdapter }) {
        this.user = user;
        this.env = env ?? "prod";
        this.storage = Storage.init(storageAdapter);
    }
}
