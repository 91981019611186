export class Storage {
    static INSTANCE;
    storageAdapter;
    static init(adapter) {
        Storage.INSTANCE = new Storage(adapter);
        return Storage.INSTANCE;
    }
    constructor(adapter) {
        this.storageAdapter = adapter;
    }
    async readBinary(key) {
        try {
            console.log("Reading Binary", key);
            return await this.storageAdapter.readBinary(key);
        }
        catch (err) {
            const message = `Error reading binary file ${key}`;
            console.log(message, err);
            throw new Error(message);
        }
    }
    async readJSON(key) {
        try {
            console.log("Reading JSON", key);
            return await this.storageAdapter.readJSON(key);
        }
        catch (err) {
            const message = `Error reading json file ${key}`;
            console.log(message, err);
            throw new Error(message);
        }
    }
    async writeBinary(opts) {
        try {
            console.log("Writing Binary", opts.id);
            return await this.storageAdapter.writeBinary(opts);
        }
        catch (err) {
            const message = `Error write binary file ${opts.id}`;
            console.log(message, err);
            throw new Error(message);
        }
    }
    async writeJSON(opts) {
        try {
            console.log("Writing JSON", opts.id ?? opts.key, this.storageAdapter);
            return await this.storageAdapter.writeJSON(opts);
        }
        catch (err) {
            const message = `Error writing json file ${opts.id ?? opts.key}`;
            console.log(message, err);
            throw new Error(message);
        }
    }
}
