import { Multitool } from "./Multitool.js";
export var RunStrategy;
(function (RunStrategy) {
    RunStrategy["SHARED_WORKER"] = "Shared Worker";
    RunStrategy["INLINE"] = "Inline";
    RunStrategy["API"] = "API";
})(RunStrategy || (RunStrategy = {}));
export class TaskDefinition {
    pkg;
    name;
    env;
    constructor({ env, pkg, name }) {
        this.pkg = pkg;
        this.name = name;
        this.env = env ?? Multitool.INSTANCE.env;
    }
    async write(_task) { }
}
