import { v4 as uuid } from "uuid";
import { File } from "./File.js";
import { TaskDefinition } from "./TaskDefinition.js";
export var TaskResultType;
(function (TaskResultType) {
    TaskResultType[TaskResultType["IMAGE"] = 0] = "IMAGE";
    TaskResultType[TaskResultType["STRING"] = 1] = "STRING";
})(TaskResultType || (TaskResultType = {}));
export var TaskStatusEnum;
(function (TaskStatusEnum) {
    TaskStatusEnum["pending"] = "pending";
    TaskStatusEnum["running"] = "running";
    TaskStatusEnum["success"] = "success";
    TaskStatusEnum["failure"] = "failure";
})(TaskStatusEnum || (TaskStatusEnum = {}));
const changeHandler = {
    set(target, prop, newValue) {
        const changed = Reflect.get(target, prop) !== newValue;
        Reflect.set(target, prop, newValue);
        if (target.onChange && changed) {
            void target.onChange(target);
        }
        return true;
    },
};
export class Task {
    def;
    id;
    args;
    status;
    result;
    resultType;
    error;
    user;
    env;
    onChange;
    constructor({ id, def, args, status, result, resultType, error, }) {
        this.id = id ?? uuid();
        this.def = def;
        this.args = args;
        Object.entries(this.args).forEach(([k, v]) => {
            if (v && v["fileType"]) {
                this.args[k] = new File(v);
            }
        });
        this.status = status ?? TaskStatusEnum.pending;
        if (result && result["fileType"]) {
            this.result = new File(result);
        }
        else {
            this.result = result;
        }
        this.resultType = resultType;
        this.error = error;
        return new Proxy(this, changeHandler);
    }
    async run() {
        try {
            await this.def.run(this);
        }
        catch (e) {
            await this.transaction(() => {
                this.status = TaskStatusEnum.failure;
                this.error = e;
            });
            console.error(e);
            throw e;
        }
    }
    async write() {
        return this.def.write(this);
    }
    hash() {
        return `${this.status}${this.result}${this.resultType}${this.user}${this.env}${this.error}`;
    }
    async transaction(tx) {
        const oc = this.onChange;
        const before = this.hash();
        try {
            this.onChange = undefined;
            tx();
        }
        finally {
            this.onChange = oc;
            const after = this.hash();
            const changed = before !== after;
            if (oc && changed) {
                await oc(this);
            }
        }
    }
    toJSON() {
        return {
            id: this.id,
            def: this.def,
            args: this.args,
            status: this.status,
            result: this.result,
            resultType: this.resultType,
            error: this.error,
            user: this.user,
            env: this.env,
        };
    }
    static async handle(task, handler) {
        try {
            console.log("handling", task);
            task.status = TaskStatusEnum.running;
            const r = await handler(task.args ?? {}, task);
            if (!r) {
                return;
            }
            await task.transaction(() => {
                task.resultType = r.resultType;
                task.result = r.result;
                task.status = TaskStatusEnum.success;
            });
            await task.write();
            return task;
        }
        catch (err) {
            await task.transaction(() => {
                task.status = TaskStatusEnum.failure;
                task.error = JSON.stringify(err);
            });
            console.log("error", err);
            await task.write();
            return task;
        }
    }
}
