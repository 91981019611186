import { Task, TaskDefinition, TaskStatusEnum, Util, } from "multitool-sdk-core";
import { fetchAPI } from "./API";
import { MultitoolBrowser } from "./MultitoolBrowser";
const PATH = "/task";
const POLLING_INTERVAL = 1000;
const TERMINAL_STATES = [TaskStatusEnum.success, TaskStatusEnum.failure];
const MAX_POLL_MS = 30 * 1000;
export class APITaskDefinition extends TaskDefinition {
    constructor(opts) {
        super(opts);
    }
    async run(task) {
        console.log("Running in API", task);
        const response = await fetchAPI(PATH, {
            user: MultitoolBrowser.INSTANCE.user,
            env: MultitoolBrowser.INSTANCE.env,
        }, {
            method: "POST",
            body: JSON.stringify(task),
            headers: {
                "Content-Type": "application/json",
            },
        });
        const { id } = await response.json();
        task.id = id;
        this.monitoredTaskId = id;
        void this.monitorStatus(task); // do not await
    }
    async getStatus(task) {
        try {
            const resp = await fetchAPI(`${PATH}/${task.id}`, {
                user: MultitoolBrowser.INSTANCE.user,
                env: MultitoolBrowser.INSTANCE.env,
            });
            return await resp.json();
        }
        catch (error) {
            console.error("Error getting task status:", error);
            throw error;
        }
    }
    monitoredTaskId = undefined;
    async monitorStatus(task) {
        let json = undefined;
        let i = 0;
        do {
            try {
                i = i + 1;
                await Util.wait(POLLING_INTERVAL);
                json = await this.getStatus(task);
                await task.transaction(() => {
                    task.status = json.status;
                    task.result = json.result;
                    task.error = json.error;
                    if (json.resultType) {
                        task.resultType = json.resultType;
                    }
                });
            }
            catch (error) {
                console.error("Error getting task status:", error);
            }
        } while (json &&
            !TERMINAL_STATES.includes(json.status) &&
            this.monitoredTaskId === task.id &&
            i < MAX_POLL_MS / POLLING_INTERVAL);
        if (i >= MAX_POLL_MS / POLLING_INTERVAL) {
            await task.transaction(() => {
                task.error = "timed out";
                task.status = TaskStatusEnum.failure;
            });
        }
    }
}
