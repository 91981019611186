import { Multitool, Task } from "multitool-sdk-core";
import { BrowserIndexedDBStorageAdapter } from "./BrowserIndexedDBStorageAdapter.js";
export class MultitoolBrowser extends Multitool {
    static INSTANCE;
    static init(opts) {
        if (!Multitool.INSTANCE) {
            MultitoolBrowser.INSTANCE = new MultitoolBrowser(opts);
            Multitool.INSTANCE = MultitoolBrowser.INSTANCE;
        }
        return Multitool.INSTANCE;
    }
    constructor({ user, env }) {
        const storageAdapter = new BrowserIndexedDBStorageAdapter();
        super({ user, env: env ?? "prod", storageAdapter });
    }
    static runWorker(handler) {
        addEventListener("connect", (e) => {
            const port = e.ports[0];
            port.addEventListener("message", async (e) => {
                const message = JSON.parse(e.data);
                if (message.type === "init") {
                    MultitoolBrowser.init({
                        env: message.env,
                        user: message.user,
                    });
                }
                else if (message.type === "task") {
                    const task = new Task(message.task);
                    task.onChange = async () => port.postMessage(JSON.stringify(task));
                    await handler(task);
                }
            });
            port.start();
        });
    }
}
