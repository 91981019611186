import { FileType, } from "multitool-sdk-core";
export class BrowserIndexedDBStorageAdapter {
    db;
    constructor() {
        if (typeof indexedDB === "undefined") {
            return;
        }
        const openRequest = indexedDB.open("multitool", 1.0);
        openRequest.onupgradeneeded = () => {
            const db = openRequest.result;
            if (!db.objectStoreNames.contains("files")) {
                db.createObjectStore("files", { keyPath: "id" });
            }
        };
        openRequest.onsuccess = () => {
            console.log("Database opened successfully", openRequest.result);
            this.db = openRequest.result;
        };
        openRequest.onerror = () => {
            console.error("Error opening database", openRequest.error);
        };
    }
    readBinary(key) {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                console.error("No database");
                resolve(undefined);
                return;
            }
            const tx = this.db.transaction("files", "readonly");
            const req = tx.objectStore("files").get(key);
            req.onsuccess = () => {
                resolve(req.result.body);
            };
            req.onerror = () => {
                console.error("failed to read binary file", req.error);
                reject("failed to read binary file");
            };
        });
    }
    readJSON(key) {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                console.error("No database");
                resolve(undefined);
                return;
            }
            const tx = this.db.transaction("files", "readonly");
            const req = tx.objectStore("files").get(key);
            req.onsuccess = () => {
                console.log("read json file", key, JSON.parse(req.result.body));
                resolve(JSON.parse(req.result.body));
            };
            req.onerror = () => {
                console.error("failed to read json file", req.error);
                reject("failed to read json file");
            };
        });
    }
    async writeBinary({ id, body }) {
        if (!this.db) {
            console.error("No database");
            return { id };
        }
        const tx = this.db.transaction("files", "readwrite");
        const req = tx
            .objectStore("files")
            .add({ body, id, fileType: FileType.BINARY });
        req.onsuccess = () => {
            console.log("saved binary file", id);
        };
        req.onerror = () => {
            console.error("failed to save binary file", req.error);
        };
        return { id: id };
    }
    async writeJSON({ id, body }) {
        if (!this.db) {
            console.error("No database");
            return { id };
        }
        const tx = this.db.transaction("files", "readwrite");
        const req = tx
            .objectStore("files")
            .put({ id, body: JSON.stringify(body), fileType: FileType.JSON });
        req.onsuccess = () => {
            console.log("saved json file", id);
        };
        req.onerror = () => {
            console.error("failed to save json file", req.error);
        };
        return { id };
    }
}
